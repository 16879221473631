<template>
  <div class="allot">
    <div
      class="simpleInfoPanel"
      v-if="hasPermission"
    >
      <ul class="filtroPanel">
        <li>
          <span>人员：</span>
          <el-input
            placeholder="输入姓名/用户名查询"
            v-model="listParams.name"
          ></el-input>
        </li>
        <li>
          <span>绑定项目：</span>
          <el-select
            clearable
            popper-class="w-block-select-down"
            v-model="listParams.projectId"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </li>
        <li class="w-input-sty">
          <span>部门：</span>
          <el-cascader
            clearable
            v-model="listParams.deploymentId"
            popper-class="black-select-panel"
            placeholder="请选择部门"
            :options="divisionOptions"
            :props="{
              expandTrigger: 'hover',
              checkStrictly: true,
              label: 'name',
              value: 'id',
              children: 'division'
            }"
          ></el-cascader>
        </li>
        <li>
          <span>角色：</span>
          <el-select
            popper-class="w-block-select-down"
            v-model="listParams.roleId"
            placeholder="请选择角色"
            clearable
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span>权益：</span>
          <el-select
            clearable
            popper-class="w-block-select-down"
            v-model="listParams.powerType"
            placeholder="请选择权益项"
          >
            <el-option
              v-for="item in rightsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span>状态：</span>
          <el-select
            clearable
            popper-class="w-block-select-down"
            v-model="listParams.state"
            placeholder="请选择分配状态"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <el-button
            class="btnPrimary btnCommon"
            @click="handlePages(1)"
          >查询</el-button>
        </li>
      </ul>
    </div>
    <div
      class="simpleInfoPanel mt15"
      v-if="hasPermission"
    >
      <el-table
        :data="tableData"
        class="tbsWrap"
      >
        <el-table-column
          prop="name"
          width="105"
          label="人员姓名"
        >
        </el-table-column>
        <el-table-column
          prop="project"
          label="绑定项目"
        >
        </el-table-column>
        <el-table-column
          prop="division"
          width="105"
          label="所属部门"
        >
        </el-table-column>
        <el-table-column
          prop="role"
          width="105"
          label="角色"
        >
        </el-table-column>
        <el-table-column
          prop="isAllot"
          width="105"
          label="是否已分配"
        >
          <template slot-scope="scope">
            {{scope.row.isAllot?'已分配':'未分配'}}
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="detail"
          label="权益分配详情"
        >
          <template slot-scope="scope">
            <div class="detailsCell">
              <span
                v-for="item in scope.row.details"
                :key="item.name"
              >
                <template v-if="item.value">
                  {{item.name+'：'+item.value}}；
                </template>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="225"
          label="操作"
        >
          <template slot-scope="scope">
            <div class="handlePanel">
              <a @click="handleDialog(scope.row, 0)">分配</a>
              <a @click="handleDialog(scope.row, 1)">回收</a>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="w-page-sty">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="listParams.total"
          :page-size="listParams.pageSize"
          :current-page="listParams.pageNum"
          @current-change="handlePages"
        >
        </el-pagination>
      </div>
      <br>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
    <el-dialog
      class="el-dialog-footer"
      :title="dialogIndex?'权益回收':'权益分配'"
      :visible.sync="dialogTableVisible"
    >
      <el-table :data="dialogData">
        <el-table-column
          prop="type"
          label="服务类型"
        >
          <template slot-scope="scope">
            <span>{{scope.row.type}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!dialogIndex"
          prop="currentNum"
          label="可分配量"
        ></el-table-column>
        <el-table-column
          v-if="dialogIndex"
          prop="rightsSum"
          label="权益总量"
        ></el-table-column>
        <el-table-column
          v-if="dialogIndex"
          prop="currentNum"
          label="当前剩余权益量"
        ></el-table-column>
        <el-table-column
          prop="num"
          :label="dialogIndex?'回收数量':'分配数量'"
        >
          <template slot-scope="scope">
            <el-input
              v-if="dialogIndex"
              v-model="scope.row.num"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
            ></el-input>
            <el-tooltip
              v-else
              effect="light"
              :content="scope.row.explain"
              placement="bottom"
            >
              <el-input
                onkeyup="this.value=this.value.replace(/\D/g,'')"
                v-model="scope.row.num"
              ></el-input>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="cancelbtn"
          @click="dialogTableVisible = false"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="submitDialog(dialogIndex, dialogData)"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'allot',
  components: {},
  data () {
    return {
      tableData: [],
      projectOptions: [],
      divisionOptions: [],
      roleOptions: [],
      rightsOptions: [
        {
          value: 1,
          label: '子账号配置'
        },
        {
          value: 2,
          label: '查看品牌权益'
        },
        {
          value: 3,
          label: '品牌联系特权'
        },
        {
          value: 5,
          label: '查看项目权益'
        },
        {
          value: 7,
          label: '项目对标权益数'
        }
      ],
      statusOptions: [
        {
          value: 0,
          label: '未分配'
        },
        {
          value: 1,
          label: '已分配'
        }
      ],
      listParams: {
        name: '', // 人员姓名或用户名 ,
        total: 0,
        pageNum: 1, // 页码
        pageSize: 10, // 每页条数 ,
        projectId: '', // 项目ID ,
        deploymentId: '', // 部门id
        roleId: '', // 角色id
        powerType: '', // 权益类型:1 子账号配置（子账号开通）;2 查看品牌权益;3 品牌联系特权;4 查看项目权益;
        state: '', // 分配状态:0 未分配;1 已分配 ,
        type: '', // 操作状态:0 分配;1 回收 ,
        isAdmin: '', // 是否主账号(不用传)
        userId: '' // 登录用户ID(不用传)
      },
      dialogTableVisible: false,
      dialogIndex: 0,
      dialogData: [
        {
          type: '子账号开通数',
          explain: '分配此权益给当前账号，其可创建子账号',
          allocableNum: '',
          num: '',
          rightsSum: '',
          currentNum: ''
        },
        {
          type: '品牌查看权益数',
          explain: '分配此权益给当前账号，其可使用权益查看品牌的“开关店分布+数据分析+拓展需求+联系方式”数据，已扣查看量的品牌其他账号可不扣量直接查看',
          allocableNum: '',
          num: '',
          rightsSum: '',
          currentNum: ''
        },
        {
          type: '品牌联系特权数',
          explain: '分配此权益给当前账号，其可使用权益对无联系方式的连锁品牌发起联系特权',
          allocableNum: '',
          num: '',
          rightsSum: '',
          currentNum: ''
        },
        {
          type: '项目查看权益数',
          explain: '分配此权益给当前账号，其可使用权益查看项目的“已签约品牌”数据，已扣查看量的项目其他账号可不扣量直接查看',
          allocableNum: '',
          num: '',
          rightsSum: '',
          currentNum: ''
        },
        {
          type: '项目对标权益数',
          explain: '查看“店铺预警分析-项目对比”的项目数',
          allocableNum: '',
          num: '',
          rightsSum: '',
          currentNum: ''
        }
      ],
      dialogDataId: '',
      userAuthorities: [],
      hasPermission: true,
      hasPermissionAll: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.getDataProjList()
    if (this.user.isadmin === 1) {
      this.dialogData = this.dialogData.filter(item => {
        return item.type !== '子账号开通数'
      })
    }
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 93
    })
    if (this.userAuthorities.length) {
      this.getDataRightsList()
      this.getDataRoleList()
      this.getDataDivision()
      this.hasPermission = true
    } else {
      this.hasPermission = false
    }
  },
  watch: {
    user (newVal, oldVal) {
    }
  },
  methods: {
    hasnotPermiss () {
      this.$message({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    submitDialog (index, array) {
      // for (let i = 0; i < this.dialogData.length; i++) {
      //   const ele = this.dialogData[i]
      //   if (ele.num === '') {
      //     this.$message({
      //       type: 'info',
      //       message: '请填写完整'
      //     })
      //     return false
      //   }
      // }
      const params = {
        totalmembernum: '', // 子账号配置（子账号开通数）个数总量 ,
        membernum: '', // 子账号配置（子账号开通数）个数余量 ,
        totallookbrandrightnum: '', // 查看品牌权益数总量 ,
        lookbrandrightnum: '', // 查看品牌权益数余量 ,
        totallook4link: '', // 品牌联系特权数总量 ,
        look4link: '', // 品牌联系特权数余量 ,
        totalsignednum: '', // 查看项目权益数总量 ,
        signednum: '', // 查看项目权益数余量 ,
        projectCompartnum: '', // 项目对标权益数 ,
        state: '', // 是否分配过权益:0 未分配;1 已分配 ,
        totalmatchnum: '', // 品牌智能匹配数总量 ,
        matchnum: '', // 品牌智能匹配数余量 ,
        userId: this.dialogDataId // 目标id
      }
      // 本地化部署不存在子账号权益
      for (const item of array) {
        if (this.user.isadmin === 0) { // 产品后面加的需求，如果是子账户就去掉子账户开通数，所以这里这样处理。
          if (item.type && item.type === '子账号开通数') {
            params.membernum = item.num
          }
        }
        if (item.type && item.type === '品牌查看权益数') {
          params.lookbrandrightnum = item.num
        } else if (item.type && item.type === '品牌联系特权数') {
          params.look4link = item.num
        } else if (item.type && item.type === '项目查看权益数') {
          params.signednum = item.num
        } else if (item.type && item.type === '项目对标权益数') {
          params.projectCompartnum = item.num
        }
      }
      if (index) {
        this.axios.post(api.recoveryPower, params).then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            this.dialogTableVisible = false
            this.getDataRightsList()
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.axios.post(api.givePower, params).then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            this.dialogTableVisible = false
            this.getDataRightsList()
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    handleDialog (data, index) {
      if (index) { // index===1时，回收
        if (this.userAuthorities && this.userAuthorities[0].func.indexOf('recov') !== -1) {
        } else {
          this.hasnotPermiss()
          return false
        }
      } else { // index===0，分配
        if (this.userAuthorities && this.userAuthorities[0].func.indexOf('distri') !== -1) {
        } else {
          this.hasnotPermiss()
          return false
        }
      }
      this.dialogIndex = index
      const params = {
        id: index ? data.id : this.user.userId
      }
      this.dialogDataId = data.id // 设置当前操作id
      for (let i = 0; i < this.dialogData.length; i++) { // 置空input
        const ele = this.dialogData[i]
        ele.num = ''
      }
      this.axios.post(api.getEditionInterests, params).then(res => {
        if (res.data && res.data.code === 0) {
          const resData = res.data.data
          if (resData) {
            this.dialogTableVisible = true
            const tempArr = [
              [
                resData.totalmembernum, // 子账号配置（子账号开通数）个数总量 ,
                resData.membernum // 子账号配置（子账号开通数）个数余量 ,
              ],
              [
                resData.totallookbrandrightnum, // 查看品牌权益数总量 ,
                resData.lookbrandrightnum // 查看品牌权益数余量 ,
              ],
              [
                resData.totallook4link, // 品牌联系特权数总量 ,
                resData.look4link // 品牌联系特权数余量 ,
              ],
              [
                resData.totalsignednum, // 查看项目权益数总量 ,
                resData.signednum // 查看项目权益数余量 ,
              ],
              [
                resData.totalprojectCompartnum, // 项目对标权益数总量 ,
                resData.projectCompartnum // 项目对标权益数余量 ,
              ]
            ]
            if ((this.user.isadmin === 1 && tempArr.length === 5) || (!resData.totalmembernum && tempArr.length === 5)) { // 当前用户是子账号时去掉
              tempArr.shift()
            }
            if ((this.user.isadmin === 1 && this.dialogData.length === 5) || (!resData.totalmembernum && this.dialogData.length === 5)) {
              this.dialogData.shift()
            }
            for (let i = 0; i < this.dialogData.length; i++) {
              const ele = this.dialogData[i]
              ele.rightsSum = tempArr[i][0]
              ele.currentNum = tempArr[i][1]
            }
          } else {
            console.log('error')
          }
        } else {
          console.log('error')
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataProjList () { // 项目列表
      this.axios.post(api.getUserProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataRoleList () { // 角色列表
      this.axios.post(api.getRoleList, {
        pageSize: 100
      }).then(res => {
        if (res.data.code === 0) {
          this.roleOptions = res.data.data.dataList
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataDivision (id) {
      this.axios.post(api.departmentManagement, {
        projectId: typeof id === 'object' ? id.join(',') : id
      }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.divisionOptions = resData.map(item => {
            if (item.managementVOS && item.managementVOS.length) {
              item.division = item.managementVOS.filter(item1 => {
                if (item1.managementVOS && item1.managementVOS.length) {
                  item1.division = item1.managementVOS.filter(item2 => {
                    return item2.type === 2
                  })
                  if (!item1.division.length) {
                    delete item1.division
                  }
                }
                return item1.type === 2
              })
            }
            return item
          })
          this.listParams.deploymentId = ''
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handlePages (num) {
      this.listParams.pageNum = num
      this.getDataRightsList()
    },
    getDataRightsList () { // 权益列表
      const params = this.listParams
      if (typeof this.listParams.deploymentId === 'object') {
        params.deploymentId = [...this.listParams.deploymentId].pop()
      } else {
        params.deploymentId = this.listParams.deploymentId
      }
      this.axios.post(api.powerlist, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.listParams.total = resData.total
          this.tableData = resData.dataList.map(item => {
            const tempObj = {
              id: item.userId,
              name: item.userName,
              project: item.projectNames,
              division: item.deploymentNames,
              role: item.roleName,
              isAllot: item.state, // 操作类型:0 分配;1 回收
              details: [
                {
                  name: '子账号开通数',
                  value: item.totalmembernum
                },
                {
                  name: '品牌查看权益数',
                  value: item.totallookbrandrightnum
                },
                {
                  name: '品牌联系特权数',
                  value: item.totallook4link
                },
                {
                  name: '项目查看权益数',
                  value: item.totalsignednum
                },
                {
                  name: '项目对标权益数',
                  value: item.totalprojectCompartnum
                }
              ]
            }
            return tempObj
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.detailsCell
  text-align left
  span
    display inline-block
    margin-right 10px
.simpleInfoPanel .filtroPanel li span
  width 64px
  text-align right
</style>
